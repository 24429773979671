/**
 * This function closes an ancestor modal, if it exists, meaning it applies a CSS class (show).
 *
 * @param {Element} targetElement - the DOM Element where the click event was detected.
 */
export const closeModal = (targetElement) => {
  const ancestorModal = targetElement.closest(".js-modal");

  if (!ancestorModal) {
    return;
  }

  // we need to remove its inner content:
  ancestorModal.innerHTML = null;
  ancestorModal.classList.remove("show");

  // we dispatch a close event with the name
  document.dispatchEvent(new Event(`modal.${ancestorModal.dataset.modalName}.close`));
};

/**
 * This function opens an ancestor modal, if it exists, meaning it removes a CSS class (show).
 *
 * @param {Element} targetElement - the DOM Element where the click event was detected.
 */
export const openModal = (targetElement, htmlContent) => {
  const { modalToShow } = targetElement.dataset;

  const modal = document.getElementsByClassName(modalToShow);

  if (!modal[0]) {
    return;
  }

  modal[0].innerHTML = htmlContent;
  modal[0].classList.add("show");
};
