import createFakeLink from "../../../packs/fake-link";

const dropdownHover = document.querySelectorAll(".js-dropdown-hover");
const dropdowns = document.querySelectorAll(".js-dropdown-hover, .js-dropdown-menu");

const mainNavigationDropdown = () => {
  dropdownHover.forEach((el) => {
    dropdownHandle(el);
  });
};

const hideDropdowns = () => {
  dropdowns.forEach((el) => {
    el.classList.remove("show");
  });
};

const dropdownHandle = (el) => {
  let hide_timer = null;
  let show_timer = null;

  el.addEventListener("mouseenter", (event) => {
    const dropdown = event.target;

    show_timer = setTimeout(function () {
      // Hide dropdowns
      hideDropdowns();

      // Show hovered dropdown after x time
      dropdown.classList.add("show");
      dropdown.querySelector(".js-dropdown-menu").classList.add("show");
    }, 150);

    clearTimeout(hide_timer);

    createFakeLink(".js-dropdown-course-link");
  });

  el.addEventListener("mouseleave", (event) => {
    const dropdown = event.target;

    // Hide hovered dropdown after x time
    hide_timer = setTimeout(function () {
      dropdown.classList.remove("show");
      dropdown.querySelector(".js-dropdown-menu").classList.remove("show");
    }, 150);

    clearTimeout(show_timer);
  });
};

mainNavigationDropdown();
