import { analytics, configure, hits, searchBox, stats } from "instantsearch.js/es/widgets";
import config from "./config";
import { resultsStats, noResultsStats, noResultsTemplate, resultsTemplateItem } from "./templates";

const { searchDmstk, container } = config;

let createWidget = [];

if (container) {
  createWidget = [
    searchBox({
      container: "#js-search-form-box",
      placeholder: searchDmstk,
      showReset: true,
      showSubmit: true,
      cssClasses: {
        input:
          "form-control form-control--placeholder-static js-search-form-input t-search-form-input",
        reset: "js-close-mobile-search-link",
        resetIcon: "d-none",
      },
      templates: {
        submit() {
          const bigIcon = document.createElement("div");
          bigIcon.classList.add("search-form__big-search-icon");

          const smallIcon = document.createElement("div");
          smallIcon.classList.add("search-form__small-search-icon");

          return bigIcon.outerHTML + smallIcon.outerHTML;
        },
      },
    }),
    configure({
      hitsPerPage: 48,
      distinct: true,
      clickAnalytics: true,
    }),
    hits({
      container: "#js-search-form-results",
      templates: {
        empty: noResultsTemplate,
        item: resultsTemplateItem(),
      },
      cssClasses: {
        list: "row search-form-results",
        item: "col-sm-6 col-md-3 col-lg-2 search-form-results__item",
      },
    }),
    stats({
      container: "#js-search-form-stats",
      templates: {
        text: (data) => {
          if (data.hasNoResults) {
            return noResultsStats(data);
          }
          return resultsStats(data);
        },
      },
    }),
    analytics({
      pushFunction: () => {
        if (typeof ga === "function") {
          ga("set", "page", window.location.pathname + window.location.search);
          ga("send", "pageView");
        }

        let queryValue = window.location.search;
        if (queryValue) {
          queryValue = queryValue.split("=");
          queryValue = decodeURI(queryValue[1]);
          Domestika.amplitude.trackEvent("search", { search_term: queryValue });
          Domestika.fbq_tracker.track_event("Search", { seach_string: queryValue });
        }
      },
    }),
  ];
} else {
  createWidget = [];
}

const widget = createWidget;

export default widget;
