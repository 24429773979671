/* eslint-disable no-param-reassign */
import amplitude from "amplitude-js";
import { amplitudeKey, cookieSessionName, cookieDeviceName } from "../utils/amplitude.js.erb";
import { setCookie, getCookie, deleteCookie } from "../utils/cookies";

const setCookieSessionId = (sessionId) => {
  const sessionCookie = getCookie(cookieSessionName);
  if (sessionCookie) {
    if (sessionId !== sessionCookie) {
      setCookie(cookieSessionName, sessionId);
    }
  } else {
    setCookie(cookieSessionName, sessionId);
  }
};

const setCookieDeviceId = (deviceId) => {
  const deviceCookie = getCookie(cookieDeviceName);
  if (deviceCookie) {
    if (deviceId !== deviceCookie) {
      setCookie(cookieDeviceName, deviceId);
    }
  } else {
    setCookie(cookieDeviceName, deviceId);
  }
};

const parseRequestAttributesFromLocation = (visitProperties) => {
  visitProperties.title = document.title;
  visitProperties.url = window.location.href;

  if (window.location.search !== "") {
    visitProperties.query = window.location.search;
    visitProperties.uri += window.location.search;
  } else {
    visitProperties.query = "none";
  }
};

class Amplitude {
  constructor() {
    const options = {
      includeReferrer: true,
      includeUtm: true,
      includeGclid: true,
    }

    amplitude.getInstance().init(amplitudeKey, null, options);
    window.amplitude = amplitude.getInstance();

    if (window.amplitude.getSessionId() == null) {
      Honeybadger.notify("[Amplitude SessionId null]", {
        context: {
          amplitudeKey,
          getSessionId: window.amplitude.getSessionId(),
          amplitudeData: window.AmplitudeData,
          userData: JSON.parse(decodeURI(atob(window.AmplitudeData.user_properties))),
        },
      });
    }

    setCookieSessionId(window.amplitude.getSessionId());
    setCookieDeviceId(window.amplitude.options.deviceId);
  }

  stopLoggingUsers() {
    window.amplitude.setOptOut(true);
  }

  trackEvent(eventName, eventProperties) {
    const userData = JSON.parse(decodeURI(atob(window.AmplitudeData.user_properties)));

    if (Object.keys(userData).length > 0) {
      window.amplitude.setUserId(userData.user_id);
      window.amplitude.setUserProperties(userData);
    }

    this.logEvent = window.amplitude.logEvent(eventName, eventProperties);
  }
}

Domestika.amplitude = new Amplitude();

// Domestika.amplitude.stopLoggingUsers(); // stop amplitude user tracking

document.addEventListener("DOMContentLoaded", () => {
  // Call generic events for set Visit in all page's
  // eslint-disable-next-line camelcase
  const { visit_properties } = window.AmplitudeData;
  parseRequestAttributesFromLocation(visit_properties);
  Domestika.amplitude.trackEvent("page_viewed", visit_properties);
});

// Onboarding events tracks
const onboardingCompleteStepButtons = document.querySelectorAll(".js-onboarding-track");

if (onboardingCompleteStepButtons) {
  onboardingCompleteStepButtons.forEach((onboardingCompleteStepButton) => {
    onboardingCompleteStepButton.addEventListener("click", () => {
      const amplitudeEventData = JSON.parse(onboardingCompleteStepButton.dataset.amplitude);
      const eventName = `complete_onboarding_${amplitudeEventData.step}`;
      const eventProperties = amplitudeEventData.event_properties;

      Domestika.amplitude.trackEvent(eventName, eventProperties);
    });
  });
}
