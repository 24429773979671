const noResultsCourses = [
  {
    id: 387,
    titles: {
      es: "Introducción a Adobe Photoshop",
      en: "Introduction to Adobe Photoshop",
      pt: "Introdução ao Adobe Photoshop",
      de: "Einführung zu Adobe Photoshop",
      fr: "Introduction à Adobe Photoshop",
      it: "Introduzione ad Adobe Photoshop",
    },
    teacher: "Carles Marsal",
    coverPath: "v1542625945/course-covers/000/000/387/387-original.jpg?1542625945",
  },
  {
    id: 404,
    titles: {
      es: "Creación y edición de contenido para Instagram Stories",
      en: "Content Creation and Editing for Instagram Stories",
      pt: "Criação e edição de conteúdo para Instagram Stories",
      de: "Erstellung und Bearbeitung von Inhalten für Instagram Stories",
      fr: "Création et édition de contenu pour Instagram Stories",
      it: "Creazione ed editing di contenuto per Instagram Stories",
    },
    teacher: "Mina Barrio",
    coverPath: "v1551722213/course-covers/000/000/404/404-original.jpg?1551722213",
  },
  {
    id: 304,
    titles: {
      es: "Introducción a After Effects",
      en: "Introduction to After Effects",
      pt: "Introdução ao After Effects",
      de: "Einführung zu After Effects",
      fr: "Introduction à After Effects",
      it: "Introduzione ad After Effects",
    },
    teacher: 'Carlos "Zenzuke" Albarrán',
    coverPath: "v1533713831/course-covers/000/000/304/304-original.jpg?1533713831",
  },
  {
    id: 470,
    titles: {
      es: "Desarrollo de un plan de medios digitales",
      en: "Development of a Digital Media Plan",
      pt: "Desenvolvimento de um plano de mídias digitais",
      de: "Entwicklung einer digitalen Mediaplanung",
      fr: "Développement d’un plan média digital",
      it: "Sviluppo di un media plan digitale",
    },
    teacher: "Foncho Ramírez-Corzo",
    coverPath: "v1553799647/course-covers/000/000/470/470-original.jpg?1553799647",
  },
  {
    id: 499,
    titles: {
      es: "Introducción a Adobe Illustrator",
      en: "Introduction to Adobe Illustrator",
      pt: "Introdução ao Adobe Illustrator",
      de: "Einführung in Adobe Illustrator",
      fr: "Introduction à Adobe Illustrator",
      it: "Introduzione ad Adobe Illustrator",
    },
    teacher: "Aarón Martínez",
    coverPath: "v1556623033/course-covers/000/000/499/499-original.jpg?1556623033",
  },
  {
    id: 381,
    titles: {
      es: "Técnicas de ilustración para desbloquear tu creatividad",
      en: "Illustration Techniques to Unlock your Creativity",
      pt: "Técnicas de ilustração para desbloquear sua criatividade",
      de: "Illustrationstechniken, um die Kreativität zu deblockieren",
      fr: "Techniques d’illustration pour débloquer votre créativité",
      it: "Tecniche di illustrazione per sbloccare la tua creatività",
    },
    teacher: "Adolfo Serra",
    coverPath: "v1555006907/course-covers/000/000/381/381-original.jpg?1555006907",
  },
  {
    id: 208,
    titles: {
      es: "Iniciación al diseño de interiores",
      en: "Introduction to Interior Design",
      pt: "Iniciação ao design de interiores",
      de: "Einführung zum Innendesign",
      fr: "Initiation au design d’intérieur",
      it: "Introduzione all’interior design",
    },
    teacher: "Masquespacio",
    coverPath: "v1510161191/course-covers/000/000/208/208-original.jpg?1510161191",
  },
  {
    id: 137,
    titles: {
      es: "Tipografía y Branding: Diseño de un logotipo icónico",
      en: "Typography and Branding: Design an Iconic Logo",
      pt: "Tipografia e concepção de marca: desenho de um logotipo icônico",
      de: "Typografie und Branding: Kultiges Logo-Design",
      fr: "Typographie et branding : Création d’un logo emblématique",
      it: "Caratteri tipografici e branding: Design di un logo iconico",
    },
    teacher: "Quique Ollervides",
    coverPath: "v1476782428/course-covers/000/000/137/137-original.jpg?1476782428",
  },
  {
    id: 303,
    titles: {
      es: "Pintar con hilo: técnicas de ilustración textil",
      en: "Painting with Thread: Textile Illustration Techniques",
      pt: "Pintar com linha: técnicas de ilustração têxtil",
      de: "Malen mit Garn: Textile Illustrationstechniken",
      fr: "Peindre avec du fil : techniques d’illustration textile",
      it: "Dipingere con filo: tecniche di illustrazione tessile",
    },
    teacher: "Gimena Romero",
    coverPath: "v1540824880/course-covers/000/000/303/303-original.jpg?1540824880",
  },
  {
    id: 537,
    titles: {
      es: "Introducción a Adobe Premiere Pro",
      en: "Introduction to Adobe Premiere Pro",
      pt: "Introdução ao Adobe Premiere Pro",
      de: "Einführung zu Adobe Premiere Pro",
      fr: "Introduction à Adobe Premiere Pro",
      it: "Introduzione ad Adobe Premiere Pro",
    },
    teacher: "Juanmi Cristóbal",
    coverPath: "v1558447617/course-covers/000/000/537/537-original.jpg?1558447617",
  },
  {
    id: 151,
    titles: {
      es: "Copywriting: define el tono de tu marca personal",
      en: "Copywriting: Define the Tone of Your Personal Brand",
      pt: "Copywriting: defina o tom de sua marca pessoal",
      de: "Copywriting: Definiere den Ton deiner persönlichen Marke",
      fr: "Copywriting : définissez le ton de votre marque personnelle",
      it: "Copywriting: definisci il tono del tuo marchio personale",
    },
    teacher: "Carla Gonzalez",
    coverPath: "v1480001842/course-covers/000/000/151/151-original.jpg?1480001842",
  },
  {
    id: 138,
    titles: {
      es: "Dibujo para principiantes nivel -1",
      en: "Drawing for Beginners Level -1",
      pt: "Desenho para principiantes nível -1",
      de: "Zeichnen für Anfänger Niveau -1",
      fr: "Dessin pour débutants niveau -1",
      it: "Disegno per principianti livello -1",
    },
    teacher: "Puño",
    coverPath: "v1473938545/course-covers/000/000/138/138-original.jpg?1473938545",
  },
  {
    id: 114,
    titles: {
      es: "Lettering 3D: modelado y texturizado con Cinema 4D",
      en: "3D Lettering: Modelling and Texturising with Cinema 4D",
      pt: "Lettering 3D: modelagem e texturização com o Cinema 4D",
      de: "Beschriftung 3D: Modellierung und Texturierung mit Cinema 4D",
      fr: "Lettering 3D : modélisation et texturisation avec Cinema 4D",
      it: "Lettering 3D: modellazione e texturizzazione con Cinema 4D",
    },
    teacher: "Zigor Samaniego",
    coverPath: "v1460468497/course-covers/000/000/114/114-original.jpg?1460468497",
  },
  {
    id: 236,
    titles: {
      es: "Introducción a las redes sociales para emprendedores creativos",
      en: "Introduction to Social Media for Creative Entrepreneurs",
      pt: "Introdução às redes sociais para empreendedores criativos",
      de: "Einführung in Social Media für kreative Unternehmer",
      fr: "Introduction aux réseaux sociaux pour les entrepreneurs créatifs",
      it: "Introduzione ai social network per imprenditori creativi",
    },
    teacher: "Pamela Barrón Cobo",
    coverPath: "v1519724656/course-covers/000/000/236/236-original.jpg?1519724656",
  },
  {
    id: 112,
    titles: {
      es: "Creación de una web profesional con WordPress",
      en: "Creation of a Professional Website with WordPress",
      pt: "Criação de um site profissional com WordPress",
      de: "Erstellung einer professionellen Webseite mit WordPres",
      fr: "Création d’un site professionnel avec WordPress",
      it: "Creazione di un sito professionale con WordPress",
    },
    teacher: "Ignacio Cruz Moreno",
    coverPath: "v1461666095/course-covers/000/000/112/112-original.jpg?1461666095",
  },
  {
    id: 154,
    titles: {
      es: "Técnicas modernas de acuarela",
      en: "Modern Watercolor Techniques",
      pt: "Técnicas modernas de aquarela",
      de: "Moderne Aquarelltechniken",
      fr: "Techniques modernes d’aquarelle",
      it: "Tecniche moderne di acquerello",
    },
    teacher: "Ana Victoria Calderon",
    coverPath: "v1481800741/course-covers/000/000/154/154-original.jpg?1481800741",
  },
  {
    id: 469,
    titles: {
      es: "Estrategia de comunicación para redes sociales",
      en: "Communication Strategy for Social Media",
      pt: "Estratégia de comunicação para redes sociais",
      de: "Social Media-Kommunikationsstrategie",
      fr: "Stratégie de communication pour réseaux sociaux",
      it: "Strategia di comunicazione per social network",
    },
    teacher: "Núria Mañé",
    coverPath: "v1553174794/course-covers/000/000/469/469-original.jpg?1553174794",
  },
  {
    id: 181,
    titles: {
      es: "Animación exprés para redes sociales con After Effects",
      en: "Express Animation for Social Media with After Effects",
      pt: "Animação rápida para redes sociais com o After Effects",
      de: "Express-Animation für soziale Netzwerke mit After Effects",
      fr: "Animation express pour réseaux sociaux avec After Effects",
      it: "Animazione express per social network con After Effects",
    },
    teacher: "Yimbo Escárrega",
    coverPath: "v1498070345/course-covers/000/000/181/181-original.jpg?1498070345",
  },
  {
    id: 266,
    titles: {
      es: "Autorretrato fotográfico artístico",
      en: "Artistic Self-Portrait Photography",
      pt: "Autorretrato fotográfico artístico",
      de: "Künstlerisches fotografisches Selbstporträt",
      fr: "Autoportrait photographique artistique",
      it: "Autoritratto fotografico artistico",
    },
    teacher: "Cristina Otero",
    coverPath: "v1533556592/course-covers/000/000/266/266-original.jpg?1533556592",
  },
  {
    id: 262,
    titles: {
      es: "De principiante a superdibujante",
      en: "Creative Drawing Techniques for Beginners",
      pt: "De iniciante a superdesenhista",
      de: "Von Anfänger bis Superzeichnen",
      fr: "Du débutant au dessinateur confirmé",
      it: "Da principiante a disegnatore provetto",
    },
    teacher: "Puño",
    coverPath: "v1529661169/course-covers/000/000/262/262-original.jpg?1529661169",
  },
  {
    id: 171,
    titles: {
      es: "Producción y edición de vídeo con cámara DSLR y Adobe Premiere",
      en: "Video Production and Edition with DSLR Camera and Adobe Premiere",
      pt: "Produção e edição de vídeo com câmera DSLR e Adobe Premiere",
      de: "Videoproduktion und -bearbeitung mit DSLR-Kamera und Adobe Premiere",
      fr: "Production et édition vidéo avec appareil DSLR et Adobe Premiere",
      it: "Produzione e video editing con videocamera DSLR e Adobe Premiere",
    },
    teacher: "Gonzalo P. Martos",
    coverPath: "v1550502007/course-covers/000/000/171/171-original.jpg?1550502007",
  },
  {
    id: 347,
    titles: {
      es: "Ilustración digital con Procreate",
      en: "Digital Illustration with Procreate",
      pt: "Ilustração digital com Procreate",
      de: "Digitale Illustration mit Procreate",
      fr: "Illustration numérique avec Procreate",
      it: "Illustrazione digitale con Procreate",
    },
    teacher: "Óscar Lloréns",
    coverPath: "v1542698759/course-covers/000/000/347/347-original.jpg?1542698759",
  },
  {
    id: 178,
    titles: {
      es: "Freelance: claves y herramientas para triunfar siendo tu propio jefe",
      en: "Freelance: Keys and Tools to Find Success as your Own Boss",
      pt: "Freelance: segredos e ferramentas para ter sucesso sendo seu próprio chefe",
      de: "Freiberuflich tätig: Schlüssel und Werkzeuge, um erfolgreich Ihr eigener Chef zu sein",
      fr: "Freelance : les clés et les outils pour réussir quand on est son propre chef",
      it: "Freelance: chiavi e strumenti per trionfare quando sei il tuo proprio capo",
    },
    teacher: "Martina Flor",
    coverPath: "v1496042708/course-covers/000/000/178/178-original.jpg?1496042708",
  },
  {
    id: 227,
    titles: {
      es: "Fotografía profesional para Instagram",
      en: "Professional Photography for Instagram",
      pt: "Fotografia profissional para Instagram",
      de: "Professionelle Fotografie für Instagram",
      fr: "Photographie professionnelle pour Instagram",
      it: "Fotografia professionale per Instagram",
    },
    teacher: "Mina Barrio",
    coverPath: "v1515496215/course-covers/000/000/227/227-original.jpg?1515496215",
  },
];

const configDataset = document.querySelector(".js-search-form")?.dataset;
const container = document.querySelector("#js-search-form-box");
const config = { ...configDataset, noResultsCourses, container };

export default config;
