// -# number_card_clicked_abs: posicion entre todas las cards de la pagina
// -# number_card_clicked_rel: posicion entre las cards de esa lista
// -# number_card_total: Total cards de la pagina
// -# list_position: Posicion de la lista entre todas las listas de la pagina
// -# total_lists: Total de listas en la pagina

class CourseCardEvents {
  static handle() {
    const courseCardsLists = document.querySelectorAll(".js-course-card-list");
    // const courseCards = document.querySelectorAll(".js-course-item");

    const dataCourseCardListsQuantity = courseCardsLists.length;
    const dataCourseCardsQuantity = document.querySelectorAll(
      ".js-course-card-list .js-course-item"
    ).length;

    let courseCardsAbsCount = 0;

    Array.from(courseCardsLists).forEach((courseCardList, courseCardListIndex) => {
      const dataListPosition = courseCardListIndex + 1;
      const courseCards = courseCardList.querySelectorAll(".js-course-item");

      Array.from(courseCards).forEach((courseCard, courseCardIndex) => {
        courseCardsAbsCount += 1;
        const dataCourseCardPositionInList = courseCardIndex + 1;
        const dataCourseCardPositionOverall = courseCardsAbsCount;

        const newAmplitudePropsObj = {
          number_card_clicked_abs: dataCourseCardPositionOverall,
          number_card_clicked_rel: dataCourseCardPositionInList,
          number_card_total: dataCourseCardsQuantity,
          list_position: dataListPosition,
          total_lists: dataCourseCardListsQuantity,
        };

        const previousAmplitudeProps = courseCard.dataset.amplitudeParentProps || "{}";
        const previousAmplitudePropsObj = JSON.parse(previousAmplitudeProps);
        const newAmplitudeProps = Object.assign(previousAmplitudePropsObj, newAmplitudePropsObj);

        courseCard.dataset.amplitudeParentProps = JSON.stringify(newAmplitudeProps);
      });
    });
  }
}

Domestika.CourseCardEvents = CourseCardEvents;

// Pre-init
Domestika.CourseCardEvents.handle();
