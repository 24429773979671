// Función genérica para llamar al track eventos actual
export const trackGAEvents = (category, action, label, value = 1, nonInteraction = null) => {
  if (action) {
    const dataEvent = {
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      eventValue: value,
    };

    if (nonInteraction !== null) {
      dataEvent.nonInteraction = nonInteraction;
    }

    Domestika.event_tracking.track_event(dataEvent);
  } else {
    throw Error("No hay eventos que trackear");
  }
};
