/**
 * This function decrypts a value that has been coded with base-64 using atob
 * - WindowBase64.atob() -.
 * @param {String} encrypted
 *
 * @return {String} the decrypted value
 */

export const decrypt = (encrypted) => {
  return atob(encrypted);
};

/**
 * This function converts a quantity of time expressed in seconds to its equivalent
 * in minutes and seconds.
 *
 * @param {Integer} duration - quantity of time in seconds.
 *
 * @return {Object.<string, number>} having the original duration, minutes and seconds.
 */

export const convertSecondsToMinutes = (duration) => {
  const minutes = duration / 60;
  const roundMinutes = Math.floor(minutes);
  const seconds = (minutes - roundMinutes) * 60;
  const roundSeconds = Math.round(seconds);
  return {
    duration,
    minutes: roundMinutes || null,
    seconds: roundSeconds || null,
  };
};

/**
 * This function gets the wording to express days in the corresponding language,
 * depending on its number (a single day or more than one).
 *
 * @param {Integer} days - the number of days.
 *
 * @return {String} the translation for the word days - or day -.
 */

export const getDays = (days) => {
  switch (true) {
    case days === 1:
      return I18n.t("datetime.distance_in_words.x_days.one");
    case days > 1:
      return I18n.t("datetime.distance_in_words.x_days.other", {
        count: days,
      });
    default:
      return 0;
  }
};

/**
 * This function gets the wording to express hours in the corresponding language,
 * depending on its number (a single hour or more than one).
 *
 * @param {Integer} hours - the number of hours.
 *
 * @return {String} the translation for the word hours - or hour -.
 */

export const getHours = (hours) => {
  switch (true) {
    case hours === 1:
      return I18n.t("datetime.distance_in_words.x_hours.one");
    case hours > 1:
      return I18n.t("datetime.distance_in_words.x_hours.other", {
        count: hours,
      });
    default:
      return 0;
  }
};

/**
 * This function gets the wording to express minutes in the corresponding language,
 * depending on its number (a single minute or more than one).
 *
 * @param {Integer} minutes - the number of minutes.
 *
 * @return {String} the translation for the word minutes - or minute -.
 */

export const getMinutes = (minutes) => {
  switch (true) {
    case minutes === 1:
      return I18n.t("datetime.distance_in_words.x_minutes.one");
    case minutes > 1:
      return I18n.t("datetime.distance_in_words.x_minutes.other", {
        count: minutes,
      });
    default:
      return 0;
  }
};

/**
 * This function gets the wording to express seconds in the corresponding language,
 * depending on its number (a single second or more than one).
 *
 * @param {Integer} seconds - the number of seconds.
 *
 * @return {String} the translation for the word seconds - or second -.
 */

export const getSeconds = (seconds) => {
  switch (true) {
    case seconds === 1:
      return I18n.t("datetime.distance_in_words.x_seconds.one");
    case seconds > 1:
      return I18n.t("datetime.distance_in_words.x_seconds.other", {
        count: seconds,
      });
    default:
      return 0;
  }
};

/**
 * This function flattens an object recursively, to obtain an object of objects with only one
 * nesting level.
 *
 * @param {Object} nestedMessages - the Object with nested elements.
 * @param {String} prefix - a prefix to use for the new key, initialized to an empty char.
 *
 * @return {Object}
 */

export const flattenMessages = (nestedMessages, prefix = "") => {
  if (nestedMessages === null) {
    return {};
  }
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === "string") {
      Object.assign(messages, { [prefixedKey]: value });
    } else if (typeof value !== "undefined") {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
};

/**
 * This function checks the data attribute called 'locale' in the body tag.
 *
 * @return {String} "es", "pt", "en", "de" or null otherwise
 */

export const getLang = document.querySelector("body")?.dataset?.locale;

/**
 * Creates an ID using random number between 0 and 1000.
 *
 * @param {String} prefix - prefix for the ID. Will use "id" as default.
 *
 * @returns {String} ID with structure <prefix><number>.
 */
export const createID = (prefix = "id") => {
  return `${prefix}${Math.floor(Math.random() * 1000 + 1)}`;
};

/**
 * This function checks if the current element or one of its children has
 * a certain CSS class.
 *
 * @param {Element} element - the DOM Element to check.
 * @param {String} classname - the classname to find.
 *
 * @return {Boolean|null}
 */

export const hasSomeChildWithClass = (element, classname) => {
  if (element.classList && element.classList.contains(classname)) {
    return true;
  }

  if (element.querySelector(`.${classname}`)) {
    return true;
  }

  return false;
};

/*
 * This function checks recursevely if the current element or one of its ancestors has
 * a certain CSS class.
 *
 * @param {Element} element - the DOM Element to check.
 * @param {String} classname - the classname to find.
 *
 * @return {Boolean|null}
 */

export const hasSomeParentWithClass = (element, classname) => {
  if (element.classList && element.classList.contains(classname)) {
    return true;
  }
  return element.parentNode && hasSomeParentWithClass(element.parentNode, classname);
};

/**
 * This function creates the configuration data to be sent in the POST requests
 * retrieving the partial that will be inject in the modal element
 *
 * @param {Object} dataset - an object with the params needede to build the URL to be requested.
 */

export const getRequestConfiguration = (baseURL, dataset) => {
  let data = dataset;

  if (!data || typeof data !== "object") {
    data = {};
  }

  let route = baseURL;
  let index = 0;

  for (const [key, value] of Object.entries(data)) {
    if (index === 0) {
      route += "?";
    }

    if (index !== 0) {
      route += "&";
    }

    route += `${key}=${value}`;
    index += 1;
  }

  const configuration = {
    headers: {
      Accept: "text/html, */*; q=0.01",
      "X-Requested-With": "XMLHttpRequest",
    },
    method: "GET",
    route,
  };

  return configuration;
};

/**
 * Gets next sibling of the element, matching a selector.
 *
 * @param {Element} element - the DOM element.
 * @param {string} selector
 *
 * @returns {Element}
 */

export const getNextSibling = (element, selector) => {
  // Get the next sibling element
  let sibling = element.nextElementSibling;

  // If there's no selector, return the first sibling
  if (!selector) return sibling;

  // If the sibling matches our selector, use it
  // If not, jump to the next sibling and continue the loop
  while (sibling) {
    if (sibling.matches(selector)) {
      return sibling;
    }
    sibling = sibling.nextElementSibling;
  }
};

/**
 * Set if an url exist or not
 *
 * @param {string} url
 *
 * @returns {Boolean}
 */

export const setIfUrlExist = (url) => {
  const pattern = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  return pattern.test(url);
};

/**
 * Get Days Number
 *
 * @returns {Object}
 */

export const getDaysNumber = () => {
  const daysCount = [];
  for (let i = 1; i <= 31; i += 1) {
    daysCount.push({
      value: i,
      label: i,
    });
  }
  return daysCount;
};

/**
 * Get Months
 *
 * @returns {Object}
 */

export const getMonthsNames = () => I18n.t("date.month_names");

/**
 * Create Array with last 60 years
 *
 * @returns {Object}
 */

export const getYearsForNow = () => {
  const max = new Date().getFullYear();
  const min = max - 60;
  const yearsCount = [];
  for (let i = max; i >= min; i -= 1) {
    yearsCount.push({
      value: i,
      label: i,
    });
  }
  return yearsCount;
};

/**
 * Check if is Base64
 *
 * @param {string} data
 *
 * @returns {Boolean}
 */

export const isBase64Valid = (data) => {
  return data.includes("base64");
};

/**
 * Check if a value is an integer between an specific range.
 *
 * @param {Number||string} day
 *
 * @param {Number||string} month
 *
 * @param {Number||string} year
 *
 * @returns {Boolean}
 */

export const isNumberInRange = (value, min, max) => {
  const parsedValue = parseInt(value, 10);

  return parsedValue >= min && parsedValue <= max && Number.isInteger(parsedValue);
};

/**
 * Convert Date to Timestamp with day, month and year
 *
 * @param {Number||string} day
 *
 * @param {Number||string} month
 *
 * @param {Number||string} year
 *
 * @returns {Number}
 */

export const convertDateToTimestamp = (day, month, year) => {
  if (
    !isNumberInRange(day, 0, 31) ||
    !isNumberInRange(month, 0, 12) ||
    !isNumberInRange(year, 1800, 3000)
  ) {
    return null;
  }

  const newDate = new Date(`${year}/${month}/${day}`);
  return newDate.getTime() / 1000;
};

/**
 * Convert Timestamp to Date day, month and year
 *
 * @param {Number} timestamp
 *
 * @returns {Object}
 */

export const convertTimestampToDate = (timestamp) => {
  const newDate = new Date(timestamp * 1000);
  return {
    day: newDate.getDate(),
    month: newDate.getMonth() + 1,
    year: newDate.getFullYear(),
  };
};

/**
 * An approach to make async functions in an optimal way
 *
 * @returns {Promise}
 */
export const rafAsync = () => {
  return new Promise((resolve) => {
    requestAnimationFrame(resolve);
  });
};

/**
 * Inserts a script tag in the page's body.
 * @param {String} - url.
 * @returns {Element} - script element.
 */
export const insertScript = (url) => {
  const script = document.createElement("script");
  script.src = url;
  script.async = true;
  script.defer = true;
  document.body.appendChild(script);

  return script;
};

/**
 * Inserts a script tag in the page's body returning a Promise.
 * @param {String} - url.
 * @returns {Promise}
 */
export const insertScriptAsync = (url) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = url;
    script.async = true;
    script.defer = true;
    script.onload = () => resolve(script);
    script.onerror = (e) => reject(e.error);
    document.body.appendChild(script);
  });
};

/**
 * Inserts a style tag in the page's head
 * @param {String} - url.
 * @returns {Element} - style element
 */
export const insertStyle = (url) => {
  const style = document.createElement("link");
  style.href = url;
  style.rel = "stylesheet";
  document.head.appendChild(style);
  return style;
};

/**
 * Removes a node from the page's body.
 * @param {Node} - node.
 */
export const removeNode = (node) => {
  if (document.body.contains(node)) {
    document.body.removeChild(node);
  }
};

/**
 * Removes a node from the page's head
 * @param {Node} - node.
 */
export const removeHeadNode = (node) => {
  if (document.head.contains(node)) {
    document.head.removeChild(node);
  }
};

/**
 * Awaits for ReCaptcha component to be available
 *
 * @returns {true}
 */
export const awaitForReCaptchaLoad = () => {
  if (window.grecaptcha === undefined) {
    return rafAsync().then(() => awaitForReCaptchaLoad());
  }
  return Promise.resolve(true);
};
