import { validateEmail, validatePassword } from "../../../utils/validations";
import { trackAmplitudeEvents, readAmplitudeProps } from "../../../utils/events/amplitude";

/**
 * SignUp Form Validation
 *
 * We search for:
 * - Email `.js-signup-form`
 * - Password `.js-signup-password`
 * - Submit `.js-signup-submit`
 *
 * @param {object} formTag - dom form object to check
 */
const signUpForm = (formTag) => {
  function setItemField(name) {
    const item = {
      field: formTag.querySelector(name),
      input: formTag.querySelector(`${name} input`),
      error: formTag.querySelector(`${name} .js-invalid-feedback`),
    };

    return item.field && item.input && item.error ? item : null;
  }

  const email = setItemField(".js-signup-email");
  const password = setItemField(".js-signup-password");
  const submitButton = formTag.querySelector(".js-signup-submit");
  const disableSubmitButton = formTag.dataset.disableSubmitOnValidation;

  // Amplitude
  const amplitudeProps = readAmplitudeProps(formTag.dataset.amplitudeProps);

  if (!email || !password || !submitButton) {
    return false;
  }

  const inputDelayTimer = 1000;
  let inputDelay = null;
  let isEmailValid = false;
  let isPasswordValid = false;
  let isFormValid = false;

  // Clear error messages
  function clearFeedback(item) {
    clearTimeout(inputDelay);

    item.input.setAttribute("data-valid", true);
    item.input.classList.remove("is-invalid");
    item.error.innerHTML = "";
  }

  function clearRailsFeedback(item) {
    const feedbacks = item.field.querySelectorAll(".invalid-feedback");

    if (feedbacks.length === 0) return;

    item.input.classList.remove("is-invalid");
    Array.from(feedbacks).forEach((feedback) => {
      item.field.removeChild(feedback);
    });
  }

  // Enable / disable submit button
  function enableSendButton(state) {
    if (disableSubmitButton !== "true") return;

    submitButton.disabled = !state;
  }

  // Validate form
  function validateForm() {
    isFormValid = isEmailValid && isPasswordValid;
    enableSendButton(isFormValid);
  }

  function trackError(name) {
    const error = name === "email" ? "invalid" : "too_short"
    const error_type = `${name}-${error}`

    trackAmplitudeEvents("authentication_error", {
      ...amplitudeProps,
      ...{
        validated_at: "frontend",
        error_type: [error_type], // we only have one error type here
      }
    });
  }

  // Show error message
  function setFeedback(item, name) {
    clearTimeout(inputDelay);

    clearRailsFeedback(item);

    item.input.removeAttribute("data-valid");

    inputDelay = setTimeout(() => {
      if (!item.input.classList.contains("is-invalid")) trackError(name);

      item.input.classList.add("is-invalid");

      if ("errorMessage" in item.field.dataset) {
        item.error.innerHTML = item.field.dataset.errorMessage;
      }
    }, inputDelayTimer);
  }

  // Validate email
  function handleValidateEmail() {
    isEmailValid = validateEmail(email.input.value);

    if (isEmailValid) {
      clearFeedback(email);
    } else {
      setFeedback(email, "email");
    }

    validateForm();
  }

  // Validate password
  function handleValidatePassword() {
    isPasswordValid = validatePassword(password.input.value);

    if (isPasswordValid) {
      clearFeedback(password);
    } else {
      setFeedback(password, "password");
    }

    validateForm();
  }

  // Events
  email.input.addEventListener("input", handleValidateEmail);
  email.input.addEventListener("change", handleValidateEmail);
  password.input.addEventListener("input", handleValidatePassword);
  password.input.addEventListener("change", handleValidatePassword);
};

// SignUp Form Init
const signUpFormInit = () => {
  const signUpFormTags = document.querySelectorAll(".js-signup-form");

  Array.from(signUpFormTags).forEach((formTag) => {
    signUpForm(formTag);
  });
};

// Init outside modal (no events listeners)
signUpFormInit();

// Init when a signUpModalOpen event is dispatched
document.addEventListener("signUpModalOpen", signUpFormInit);
