import axios from "axios";
import {
  API_PREFIX,
  AUTHORIZATION_KEY,
  REFRESH_TOKEN_KEY,
  TOKEN_KEY,
  TOKEN_TYPE,
} from "./constants";

import { getHeaders } from "./headers";

const restAxiosInstance = axios.create();

function refreshAccessToken() {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
  if (!refreshToken) {
    return Promise.reject();
  }

  const route = `/oauth/token?grant_type=refresh_token&refresh_token=${refreshToken}`;
  return requestApi({
    method: "POST",
    route,
  }).then((response) => {
    if (response.data) {
      const { access_token, refresh_token } = response.data;
      localStorage.setItem(TOKEN_KEY, access_token);
      localStorage.setItem(REFRESH_TOKEN_KEY, refresh_token);
      return access_token;
    }
  });
}

function requestApi({ method, headers = {}, route, data, formData } = {}) {
  const configRequest = {
    method,
    credentials: "same-origin",
    "Access-Control-Allow-Origin": "*",
    headers: getHeaders({ customHeaders: headers, hasFormData: !!formData }),
    url: route,
    data,
  };

  const payloadMethods = ["post", "put", "patch"];
  if (payloadMethods.indexOf(method.toLowerCase()) >= 0) {
    configRequest.data = !formData ? JSON.stringify(data) : data;
  }

  return restAxiosInstance(configRequest.url, configRequest);
}

(function registerInterceptors() {
  restAxiosInstance.interceptors.request.use((request) => {
    if (request.url.includes(API_PREFIX)) {
      const token = localStorage.getItem(TOKEN_KEY);
      request.headers[AUTHORIZATION_KEY] = `${TOKEN_TYPE} ${token}`;
    }
    return request;
  });

  restAxiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (
        error.config.url.includes(API_PREFIX) &&
        error.response &&
        error.response.status === 401
      ) {
        const originalRequest = { ...error.config };
        if (!originalRequest._retry) {
          originalRequest._retry = true;
          try {
            const newToken = await refreshAccessToken();
            if (!newToken) {
              return Promise.reject("It was not possible to get the new token!");
            }

            restAxiosInstance.defaults.headers[AUTHORIZATION_KEY] = `${TOKEN_TYPE} ${newToken}`;
            return restAxiosInstance(originalRequest);
          } catch (error) {
            return Promise.reject(error);
          }
        }
      }

      return Promise.reject(error);
    }
  );
})();

export {
  TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  AUTHORIZATION_KEY,
  TOKEN_TYPE,
  restAxiosInstance,
  requestApi,
};
