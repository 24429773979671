/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_bundle_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import "./utils/events/globalEventsTracker";
import "./molecules/search";
import "./molecules/navigation/mainDropdown";
import "./molecules/navigation/events";
import "./molecules/fields/showPasswordField";
import "./organism/modalLogin/index";
import "./organism/modalCourses/index";
import "../packs/amplitude/amplitude";
import "./organism/modalGdpr/index";
import "../packs/alerts/fixedAlert";
import "./utils/widgets/apple";
import "./molecules/alertJuguetona/alertJuguetona";
import "./molecules/alertJuguetona/events";
import "./molecules/stickyBanner";
import "./organism/courseCard/events";
import "./organism/referralStatusPopover"; // to set up events to display referral status popovers (MGM)
import "./organism/topbar/sabrosona";
import "./organism/tooltip";
import "./molecules/fields/emailCorrectorField";
import "./molecules/fields/signUpForm";
import "./atoms/modals/modals";
import "./atoms/alertBar/events";
import "./organism/userMenu/events";
import "./molecules/footer/events";
import "./molecules/fields/enterFocusEmail";
