import { trackMultipleComponents } from "./trackComponent";

const AmplitudeTrackSelector = ".js-amplitude-track";
const GATrackSelector = ".js-ga-track";

/**
 * Global Tracker for Google Analytics and Amplitude
 * Any DOM element using .js-amplitude-track class, will be tracked by Amplitude
 * Any DOM element using .js-ga-track class, will be tracked by Google Analytics
 * Check available dataset supported for each one of them
 */
const startGlobalEventsTracker = (amplitudeSelector, gaSelector) => {
  trackMultipleComponents(`${amplitudeSelector},${gaSelector}`);
};

startGlobalEventsTracker(AmplitudeTrackSelector, GATrackSelector);

Domestika.startGlobalEventsTracker = () => {
  startGlobalEventsTracker(AmplitudeTrackSelector, GATrackSelector);
};
