export const setCookie = (cname, cvalue, exdays) => {
  const date = new Date();
  date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
};

export const getCookie = (cname) => {
  const name = `${cname}=`;
  const decodedCookie = document.cookie;
  const cookies = decodedCookie.split(";");
  for (let i = 0; i < cookies.length; i += 1) {
    let item = cookies[i];
    while (item.charAt(0) === " ") {
      item = item.substring(1);
    }
    if (item.indexOf(name) === 0) {
      return item.substring(name.length, item.length);
    }
  }
  return "";
};

export const deleteCookie = (cname) => {
  setCookie(cname, "", 0);
};
