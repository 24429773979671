import { history as historyRouter } from "instantsearch.js/es/lib/routers";
import config from "./config";

const { indexName } = config;

const router = historyRouter({
  createURL({ qsModule, routeState }) {
    const baseUrl = document.location.href.split("?")[0];

    const queryParameters = {};

    if (routeState.query) {
      queryParameters.query = encodeURIComponent(routeState.query);
    }

    const queryString = qsModule.stringify(queryParameters, {
      addQueryPrefix: true,
      arrayFormat: "repeat",
    });

    return `${baseUrl}${queryString}`;
  },

  parseURL({ qsModule, location }) {
    const { query = "" } = qsModule.parse(location.search.slice(1));

    return {
      query: decodeURIComponent(query),
    };
  },
});

const stateMapping = {
  stateToRoute(uiState) {
    const indexUiState = uiState[indexName] || {};
    return {
      query: indexUiState.query,
    };
  },

  routeToState(routeState) {
    const obj = {};
    obj[indexName] = {
      query: routeState.query,
    };
    return obj;
  },
};

const searchRouting = {
  router,
  stateMapping,
};

export default searchRouting;
