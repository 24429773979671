import { addAmplitudeParentProps, readAmplitudeProps, trackAmplitudeEvents } from "./amplitude";

import { trackGAEvents } from "./ga";

/**
 * Register the Trackers given the attributes on Element's DataSet
 * @param {*} element
 * @param {*} defaultEventValues
 */
function registerTrackersForElement(element, { category = "", action = "", label = "" } = {}) {
  // TRACK A ELEMENT
  // You can track an element just using his selector and get the data from data-attributes
  const {
    trackCategory = category,
    trackAction = action,
    trackLabel = label,
    amplitudeParentPropsSelector,
  } = element.dataset;

  let { amplitudeProps } = element.dataset;

  const elementTrackers = [];

  // Google Analytics Tracking
  if (trackCategory && trackAction) {
    elementTrackers.push(() => trackGAEvents(trackCategory, trackAction, trackLabel));
  }

  // Amplitude Tracking
  if (amplitudeProps) {
    elementTrackers.push(() => {
      if (amplitudeParentPropsSelector) {
        const amplitudeParentElement = document.getElementById(amplitudeParentPropsSelector);
        amplitudeProps = addAmplitudeParentProps(amplitudeParentElement, amplitudeProps);
      }

      const { eventName, eventProperties } = readAmplitudeProps(amplitudeProps);
      trackAmplitudeEvents(eventName, eventProperties);
    });
  }

  // Only if having either GA or Amplitude registered as trackers, we then create the listener
  if (elementTrackers.length) {
    createEventListener(element, () => {
      elementTrackers.forEach((eventTracker) => eventTracker());
    });
  }
}

/**
 * Track a Component (GA and/or Amplitude) given a selector
 * @param {*} trackerParams
 */
export const trackComponent = ({
  // ONE SELECTOR
  selector,
  category = null,
  action = null,
  label = null,

  // TRACK IF SHOWN
  // When 'trackIfShown' is true use 'ifShownAction' to set a default action for this event
  trackIfShown = false,
  ifShownAction,
  nonInteraction = null,

  // CHILDREN
  // Options for children if there are
  childrenSelector = ".js-track-event",
  childrenDefaultCategory = category,
  childrenDefaultAction = null, // Default action for children when it has no 'data-track' attributes but it has 'childrenSelector'
}) => {
  const element = document.querySelector(selector);
  if (!element) {
    return;
  }

  // Google Analytics tracking IF SHOWN element
  // Track if a element is shown (works only for the parent element)
  if (trackIfShown && ifShownAction) {
    trackGAEvents(category, ifShownAction, window.location.href, undefined, nonInteraction);
  }

  registerTrackersForElement(element, { category, action, label });

  // TRACK CHILDREN
  // You can track childrend just using the 'childrenSelector' class name
  const childrenElements = element.querySelectorAll(childrenSelector);
  if (childrenElements && childrenElements.length) {
    childrenElements.forEach((childElem) => {
      registerTrackersForElement(childElem, {
        category: childrenDefaultCategory,
        action: childrenDefaultAction,
      });
    });
  }
};

/**
 * Generic Elements Tracker (GA and/or Amplitude), given a selector
 * This serves the purpose of globally tracking any element using GA class tracker or Amplitude class tracker
 * Note: only attributes on the DOM element will be used as properties, see below:
 * GA DOM attributes:
 *  - {string} trackCategory
 *  - {string} trackAction
 *  - {string} trackLabel
 * Amplitude DOM attributes:
 *  - {Object} amplitudeProps - { event_name: string; event_properties: any{} }
 * @param {string} selector
 */
export const trackMultipleComponents = (selector) => {
  const elements = document.querySelectorAll(selector);
  if (!elements) {
    return;
  }

  elements.forEach((element) => {
    registerTrackersForElement(element);
  });
};

/**
 * Creates an EventListener for the element received, executing the callback function provided
 * You can pass custom events to be listened to, the default is 'click'
 * @param {*} element
 * @param {*} callbackTrackerFn
 * @param {*} event - Default: 'click'
 */
export function createEventListener(element, callbackTrackerFn, event = "click") {
  if (!element) {
    throw Error("The element is mandatory for registering an Event Listener");
  }

  if (!callbackTrackerFn) {
    throw Error("The callback function is mandatory for registering an Event Listener");
  }

  element.addEventListener(event, callbackTrackerFn);
}
