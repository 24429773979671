import Fuse from "fuse.js";
import { mostUsedDomains } from "./utils";
import { trackAmplitudeEvents } from "../../../utils/events/amplitude";

const fuse = new Fuse(mostUsedDomains, {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 10,
  maxPatternLength: 32,
  minMatchCharLength: 2,
});

const emailCorrectorField = (emailField) => {
  const emailInput = emailField.querySelector("input");
  const messageCopy = emailField.getAttribute("data-email-corrector-copy");
  const delayTimer = 1000;
  let messageDiv;
  let myDelay = null;
  let amplitudeProps = null;
  let prevEmailSuggestion = null;

  // Amplitude
  const emailFieldData = emailField.dataset;

  amplitudeProps = {
    page_uri: emailFieldData.amplitudePropsPrevPageUri,
    page_type: emailFieldData.amplitudePropsPageType,
    page_subtype: emailFieldData.amplitudePropsPageSubtype,
    prev_page_uri: emailFieldData.amplitudePropsPageUri,
    validated_at: "frontend",
    error_field: "email",
  };

  emailInput.addEventListener("input", handleInput, false);

  function getNextSibling(elem, selector) {
    // Get the next sibling element
    var sibling = elem.nextElementSibling;

    // If there's no selector, return the first sibling
    if (!selector) return sibling;

    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
      if (sibling.matches(selector)) return sibling;
      sibling = sibling.nextElementSibling;
    }
  }

  function createMessage() {
    if (messageDiv) return;

    messageDiv = document.createElement("div");
    messageDiv.id = "email-suggestion";
    messageDiv.classList.add("o-email-corrector__message");
  }

  function destroyMessage() {
    if (messageDiv) {
      messageDiv.classList.add("d-none");
    }
  }

  function clearRailsFeedback(item) {
    const feedback = getNextSibling(item, ".invalid-feedback");

    if (!feedback) return;

    item.classList.remove("is-invalid");
    feedback.remove();
  }

  function handleInput(event) {
    clearTimeout(myDelay);
    myDelay = setTimeout(() => {
      checkValue(event);
    }, delayTimer);
  }

  function fillValue(value) {
    emailInput.value = `${emailInput.value.split("@")[0]}@${value}`;
    emailInput.dispatchEvent(new Event("change"));

    destroyMessage();

    // Amplitude correct email event
    trackAmplitudeEvents("signup_email_suggestion_clicked", amplitudeProps);
  }

  function showMessage(value) {
    // Check if domain is in list
    if (mostUsedDomains.includes(value)) {
      return false;
    }

    const domainSuggested = getSuggestion(value);

    if (domainSuggested) {
      createMessage();

      messageDiv.innerHTML = messageCopy.replace("[DOMAIN]", domainSuggested);
      messageDiv.dataset.domain = domainSuggested;
      messageDiv.classList.remove("d-none");
      emailInput.after(messageDiv);

      // Amplitude show message event
      if (prevEmailSuggestion !== domainSuggested) {
        amplitudeProps = { ...amplitudeProps, ...{ suggested_value: domainSuggested } };
        trackAmplitudeEvents("signup_email_suggestion_viewed", amplitudeProps);
      }

      prevEmailSuggestion = domainSuggested;

      messageDiv.addEventListener("click", () => {
        fillValue(domainSuggested);
      });
    }

    return false;
  }

  function checkValue(event) {
    destroyMessage();
    const { value } = event.target;

    if (
      value.indexOf("@") !== -1 &&
      value.split("@")[0] !== "" &&
      emailInput.hasAttribute("data-valid")
    ) {
      clearRailsFeedback(event.target);
      console.log(event.target)
      showMessage(value.split("@")[1]);
    } else {
      destroyMessage();
    }
  }

  function getSuggestion(value) {
    const filteredDomains = fuse.search(value);
    if (filteredDomains.length !== 0) {
      return filteredDomains[0].item;
    }

    return false;
  }
};

export const emailCorrectorFieldsInit = () => {
  const emailFields = document.querySelectorAll(".js-email-corrector");

  Array.from(emailFields).forEach((emailField) => {
    emailCorrectorField(emailField);
  });
};

Domestika.emailCorrectorFieldsInit = emailCorrectorFieldsInit;

// Pre-init
Domestika.emailCorrectorFieldsInit();
