import { trackComponent } from "../../utils/events/trackComponent";

trackComponent({
  selector: ".js-navbar-track",
  childrenSelector: ".js-dropdown-course-link",
  category: "header"
});

trackComponent({
  selector: "#add-thing-dropdown",
  category: "header",
  action: "click - plus_sign_icon"
});

trackComponent({
  selector: ".js-notifications-dropdown",
  category: "header",
  action: "click - notifications_icon"
});

