class TopbarSabrosona {
  static handle() {
    const topbarCountdown = document.querySelector(".js-topbar-sabrosona-countdown");

    if (topbarCountdown) {
      const closeTopbar = document.querySelector(".js-topbar-close");
      const { countdownTo } = topbarCountdown.dataset;

      if (countdownTo) {
        const momentTo = moment.utc(countdownTo, "YYYY/MM/DD HH:mm:ss");

        const countdownDays = document.querySelector(".js-topbar-sabrosona-countdown-days > span");
        const countdownHours = document.querySelector(
          ".js-topbar-sabrosona-countdown-hours > span"
        );
        const countdownMins = document.querySelector(
          ".js-topbar-sabrosona-countdown-minutes > span"
        );
        const countdownSecs = document.querySelector(
          ".js-topbar-sabrosona-countdown-seconds > span"
        );

        // eslint-disable-next-line no-undef
        $(topbarCountdown).countdown(momentTo.toDate(), (event) => {
          if (countdownDays) countdownDays.innerText = event.strftime("%-D");

          countdownHours.innerText = event.strftime("%H");
          countdownMins.innerText = event.strftime("%M");
          countdownSecs.innerText = event.strftime("%S");
        });

        if (closeTopbar) {
          // eslint-disable-next-line no-undef
          $(topbarCountdown).on("finish.countdown", () => {
            closeTopbar.click();
          });
        }
      }
    }
  }
}

TopbarSabrosona.handle();
