import { getDays, getHours, getMinutes, getSeconds } from "../../utils/functions";

class Countdown {
  init() {
    const countDowns = document.getElementsByClassName("js-countdown");
    if (countDowns) {
      for (const item of countDowns) {
        let momentTo;
        const countdownTo = item.getAttribute("data-countdown-to");
        const humanize = item.classList.contains("humanize");
        if (countdownTo) {
          momentTo = moment.utc(countdownTo, "YYYY/MM/DD HH:mm:ss");
          // eslint-disable-next-line no-undef, no-loop-func
          $(item).countdown(momentTo.toDate(), (data) => {
            const days = data.offset.totalDays;
            this.dateFormat = "";
            if (humanize) {
              const { hours, minutes, seconds } = data.offset;
              switch (true) {
                case days && !hours:
                  this.dateFormat = `${getDays(days)}.`;
                  break;
                case days > 0 && hours > 0:
                  this.dateFormat = `${getDays(days)}, ${getHours(hours)}.`;
                  break;
                case !days && hours > 0 && minutes > 0:
                  this.dateFormat = `${getHours(hours)}, ${getMinutes(minutes)}.`;
                  break;
                case !days && hours > 0 && !minutes:
                  this.dateFormat = `${getHours(hours)}.`;
                  break;
                case !days && !hours && minutes > 0 && seconds > 0:
                  this.dateFormat = `${getMinutes(minutes)}, ${getSeconds(seconds)}.`;
                  break;
                case !days && !hours && minutes > 0 && !seconds:
                  this.dateFormat = `${getMinutes(minutes)}.`;
                  break;
                case !days && !hours && !minutes && seconds > 0:
                  this.dateFormat = `${getSeconds(seconds)}.`;
                  break;
                default:
                  item.parentNode.remove();
              }
            } else {
              this.dateFormat = days > 0 ? "%-Dd · %H:%M:%S" : "%H:%M:%S";
            }
            // eslint-disable-next-line no-undef
            $(item).text(data.strftime(this.dateFormat));
            return this.dateFormat;
          });
        }
      }
    } else {
      throw new Error("No se ha encontrado ningún CountDown definido en esta página");
    }
  }
}

Domestika.countdown = new Countdown();
