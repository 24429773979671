import { requestApi } from "../../utils/requests";
import { AJAX_COMPLETE } from "../../utils/requests/constants";
import { trackPrometheusEvent } from "../../utils/events/prometheus";
import { readAmplitudeProps, trackAmplitudeEvents } from "../../utils/events/amplitude";

function handleSubmit(event) {
  event.preventDefault();

  const form = event.target;

  // eslint-disable-next-line no-undef
  const formData = new FormData(form);

  requestApi({
    method: event.target.method,
    route: event.target.action,
    data: formData,
    formData: true,
  }).then((response) => {
    if (response && response.status >= 200 && response.status < 300) {
      document.querySelector("[data-cookies-advice]").classList.add("d-none");
      document.querySelector("#cookies-preferences-modal").classList.add("d-none");
      // eslint-disable-next-line no-undef
      document.dispatchEvent(new Event(AJAX_COMPLETE));
    }
  });
}

function addListerToCookieForms() {
  const cookiesForms = document.querySelectorAll(".cookie-advice-form");
  cookiesForms.forEach((form) => {
    form.addEventListener("submit", handleSubmit);
  });
}

document.addEventListener("modal.cookie_preferences.open", addListerToCookieForms);

addListerToCookieForms();

// *** Track 'click' for accepting cookies [Cookie banner (no-gdpr)] ***
// Event => development_v1_cookie_banner{action="click",server_role="localhost"} 1
const okCookies = document.querySelector("#accept_cookies_banner");

if (okCookies != null) {
  okCookies.addEventListener("click", (e) => {
    trackPrometheusEvent("cookie_banner", "click");
  });
}

// *** Track 'click' for accepting cookies [Cookie popup (gdpr)] ***
// Event => development_v1_cookie_banner_gdpr{action="accepted",server_role="localhost"} 1
const acceptedAllCookies = document.querySelector("#accept_cookies_modal");

if (acceptedAllCookies != null) {
  acceptedAllCookies.addEventListener("click", (e) => {
    trackPrometheusEvent("cookie_banner_gdpr", "accepted");
  });
}

// *** Track 'click' for managing cookies [Cookie popup (gdpr)] ***
// Event => development_v1_cookie_banner_gdpr{action="manage",server_role="localhost"} 1
const manageCookies = document.querySelector("#open_cookies_modal");

if (manageCookies != null) {
  manageCookies.addEventListener("click", (e) => {
    trackPrometheusEvent("cookie_banner_gdpr", "manage");
  });
}

// *** Track 'show' for managing cookies [Cookie popup (normal)] ***
const cookieBannerNormal = document.querySelector(".js-cookie-banner-normal");

if (cookieBannerNormal != null) {
  trackAmplitudeEvents("show_cookie_popup", { banner: "normal" });
}

// *** Track 'show' for managing cookies [Cookie popup (gdpr)] ***
const cookieBannerGdpr = document.querySelector(".js-cookie-banner-gdpr");

if (cookieBannerGdpr != null) {
  trackAmplitudeEvents("show_cookie_popup", { banner: "gdpr" });
}

