import { trackComponent } from "../../utils/events/trackComponent";

trackComponent({
  selector: ".hellobar--mgm",
  trackIfShown: true,
  ifShownAction: "show-alert",
  childrenDefaultAction: "alert",
  category: "mgm",
  nonInteraction: true,
});

trackComponent({
  selector: ".hellobar--mgm",
  childrenSelector: ".close",
  childrenDefaultAction: "close-alert",
  category: "mgm",
});

trackComponent({
  selector: ".hellobar--sabrosona",
  trackIfShown: true,
  ifShownAction: "show-alert",
  childrenDefaultAction: "alert",
  category: "promo-alert-sabrosona",
  nonInteraction: true,
});

trackComponent({
  selector: ".hellobar--sabrosona",
  childrenSelector: ".close",
  childrenDefaultAction: "close-alert",
  category: "promo-alert-sabrosona",
});

trackComponent({
  selector: ".hellobar--basic",
  trackIfShown: true,
  ifShownAction: "show-alert",
  childrenDefaultAction: "alert",
  category: "promo-alert-basic",
  nonInteraction: true,
});

trackComponent({
  selector: ".hellobar--basic",
  childrenSelector: ".close",
  childrenDefaultAction: "close-alert",
  category: "promo-alert-basic",
});
