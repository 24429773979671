import algoliasearch from "algoliasearch";
import InstantSearch from "instantsearch.js";
import config from "./config";
import searchRouting from "./search-routing";

const { indexName, container } = config;

// Init Algolia
const searchClient = algoliasearch("FNSM5OIUDB", "b1f1342eecc4a024d5e023443fa50134");

// When the search input has content
const inputDropdownSearchShow = () => {
  const searchFormInput = document.querySelector(".js-search-form-input");
  const searchResultsDropdown = document.getElementById("js-dropdown-search");
  const closeSearchLink = document.querySelector(".js-close-mobile-search-link");

  if (searchFormInput.value.length) {
    searchResultsDropdown.classList.add("show");
    closeSearchLink.classList.remove("d-none");
  } else {
    searchResultsDropdown.classList.remove("show");
    closeSearchLink.classList.add("d-none");
  }
};

// Toggle mobile search form
const toggleMobileSearchForm = () => {
  const openMobileSearchLink = document.querySelector(".js-open-mobile-search-link");
  const closeMobileSearchLink = document.querySelector(".js-close-mobile-search-link");
  const searchForm = document.querySelector(".js-search-form");
  const searchFormInput = document.querySelector(".js-search-form-input");

  openMobileSearchLink.addEventListener("click", (event) => {
    searchForm.classList.remove("d-none");
    searchFormInput.focus();
    event.preventDefault();
  });

  closeMobileSearchLink.addEventListener("click", () => {
    searchForm.classList.add("d-none");
    searchFormInput.value = "";
  });
};

// Search
let instantsearch;
if (container) {
  instantsearch = InstantSearch({
    indexName,
    searchClient,
    routing: searchRouting,
    searchFunction: (helper) => {
      const queryString = helper.state.query;
      const searchForm = document.querySelector(".js-search-form");
      const searchResultsDropdown = document.getElementById("js-dropdown-search");
      const searchFormInput = document.querySelector(".js-search-form-input");

      if (!searchResultsDropdown.classList.contains("js-dropdown-search--traditional")) {
        inputDropdownSearchShow();
      }
      toggleMobileSearchForm();

      if (!queryString?.trim()) {
        document.body.classList.remove("searching");
        searchFormInput.classList.remove("ais-SearchBox-input--has-content");
      } else {
        searchFormInput.dispatchEvent(new Event("input"));
        searchFormInput.focus();
        searchFormInput.classList.add("ais-SearchBox-input--has-content");

        document.body.classList.add("searching");
        searchForm.classList.remove("d-none");
        helper.search();
      }
    },
  });
}
const search = instantsearch;

export default search;
