import sanitizeHtml from "sanitize-html";
import config from "./config";

const { locale, courseBy, resultsTitle, noResultsTitle, noResultsExp, noResultsCourses } = config;

const resultsStats = (data = {}) => {
  const maxHits = 48;

  const hitsCount = () => {
    if (data.nbHits > maxHits) {
      return maxHits;
    }
    return data.nbHits;
  };

  return `<p>${hitsCount()} ${resultsTitle} “<span>${sanitizeHtml(data.query)}</span>”</p>`;
};

const noResultsStats = (data = {}) => {
  return `<h3 class="h1 search-form-stats__title">${noResultsTitle} “<span>${sanitizeHtml(
    data.query
  )}</span>”</h3>
          <p>${noResultsExp}</p>`;
};

const resultsTemplateItem = (course = {}) => {
  const imageUrlChunk = "https://cdn.domestika.org/c_fill,t_base_params,";
  // course es {} cuando se encuentran resultados en algolia y
  // es igual a cada uno de los course de config.js cuando no hay resultados
  // los string {{ key }} son unicamente para la interpolacion que hace algolia
  const {
    id = "{{ objectID }}",
    teacher = "{{ teacher_fullname_or_permalink }}",
    title = `{{ title }}`,
    coverPath = "{{ cover_cl_path }}",
  } = course;

  return `<a href="/${locale}/courses/${id}" class="row">
            <div class="col-5 col-sm-12">
              <div class="search-form-results__cover">
                <picture>
                  <source media="only screen and (max-width: 479px)"
                    srcset="${imageUrlChunk}dpr_1,w_180,h_101/${coverPath}, ${imageUrlChunk}dpr_2,w_180,h_101/${coverPath} 2x">
                  <source media="only screen and (min-width: 480px ) and (max-width: 767px)"
                    srcset="${imageUrlChunk}dpr_1,w_364,h_205/${coverPath}, ${imageUrlChunk}dpr_2,w_364,h_205/${coverPath} 2x">
                  <source media="only screen and (min-width: 768px ) and (max-width: 991px)"
                    srcset="${imageUrlChunk}dpr_1,w_228,h_128/${coverPath}, ${imageUrlChunk}dpr_2,w_228,h_128/${coverPath} 2x">
                  <source media="only screen and (min-width: 992px)"
                    srcset="${imageUrlChunk}dpr_1,w_180,h_101/${coverPath}, ${imageUrlChunk}dpr_2,w_180,h_101/${coverPath} 2x">
                  <img src="${imageUrlChunk}dpr_1,w_180,h_101/${coverPath}">
                </picture>
              </div>
            </div>
            <div class="col-7 col-sm-12 no-gutter-left">
              <h3 class="h4 search-form-results__title">${title}</h3>
              <p class="search-form-results__teacher">${courseBy} ${teacher}</p>
            </div>
          </a>`;
};

const noResultsTemplate = `<ol class="row ais-Hits-list search-form-results">
    ${noResultsCourses
      .map((noResultsCourse) => {
        return `<li class= "col-sm-6 col-md-3 col-lg-2 search-form-results__item">${resultsTemplateItem(
          { ...noResultsCourse, title: noResultsCourse.titles[locale] }
        )}</li>`;
      })
      .join("")}
  </ol>`;

export { resultsStats, noResultsStats, resultsTemplateItem, noResultsTemplate };
