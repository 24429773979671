import * as cookiesUtils from "../../../packs/utils/cookies";

class stickyBanner {
  // eslint-disable-next-line class-methods-use-this
  init() {
    const stickyBannerElement = document.querySelector(".js-sticky-banner");

    if (stickyBannerElement) {
      const stickyBannerCookie = stickyBannerElement.dataset.bannerId;
      const stickyBannerCloseButton = stickyBannerElement.querySelector(
        ".js-sticky-banner-close-button"
      );

      if (stickyBannerCloseButton) {
        stickyBannerCloseButton.addEventListener("click", async () => {
          stickyBannerElement.remove();

          cookiesUtils.setCookie(`stickyBannerClosed_${stickyBannerCookie}`, true, 4000);
        });
      }
    }
  }
}

Domestika.stickyBanner = new stickyBanner();

// Pre-init
Domestika.stickyBanner.init();
