export function trackAmplitudeEvents(eventName, eventProperties) {
  if (eventName) {
    Domestika.amplitude.trackEvent(eventName, eventProperties);
  } else {
    throw Error("No event to be tracked");
  }
}

/**
 * Convert the String format of Amplitude properties and validates mandatory fields
 * @param {*} amplitudeProps
 */
export function readAmplitudeProps(amplitudeProps) {
  if (!amplitudeProps) {
    throw Error("No Amplitude props to read!");
  }

  const amplitudeObjProps = JSON.parse(amplitudeProps);

  return amplitudeObjProps;
}

/**
 * Combines the properties from the parent with the tracked element
 * @param {*} parentElement
 * @param {*} amplitudeProps
 */
export function addAmplitudeParentProps(parentElement, amplitudeProps) {
  if (!parentElement) {
    throw Error("No parent selector found!");
  }

  if (!amplitudeProps) {
    throw Error("No Amplitude props to read!");
  }

  const parentElementObjProps = JSON.parse(parentElement.dataset.amplitudeParentProps);
  const amplitudeObjProps = JSON.parse(amplitudeProps);
  const mergedAmplitudeProps = Object.assign(
    amplitudeObjProps.eventProperties,
    parentElementObjProps
  );

  return JSON.stringify(
    Object.assign(amplitudeObjProps, { eventProperties: mergedAmplitudeProps })
  );
}
